.logo-text {
  display: flex;
  align-items: center;
  .text {
    font-size: 2.8rem;
    font-weight: 700;
    padding-left: 1.6rem;
    color: #080b11;
  }
}

.header {
  margin-bottom: 4rem;
}
.form {
  width: 40rem;
  .text-box,
  .button {
    margin-top: 2.4rem;
  }
  @media (max-width: 480px) {
    width: 32rem;
  }
}

.options {
  display: flex;
  gap: 3.2rem;
  .option-card {
    width: 50%;
  }
}

.step-four {
  .button {
    margin-top: 0;
  }
  .icon-container {
    margin-bottom: 4.8rem;
    border-radius: var(--e-r-round);
    padding: 1.6rem;
    background-color: var(--e-cl-pri);
    color: var(--e-cl-bg);
  }
}

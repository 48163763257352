.text-box {
  display: inline-block;
  font-size: 1.4rem;
  width: 100%;
  .label {
    margin-bottom: 0.8rem;
    .label-hint {
      color: var(--e-cl-tx-sec-50pc);
    }
  }
  .wrapper {
    display: flex;
    border-radius: var(--e-r-default);
    border: 1px solid var(--e-cl-border);
    .prefill {
      color: var(--e-cl-tx-sec);
      padding: 1.3rem 1.6rem;
      border-right: 1px solid var(--e-cl-border);
      background-color: var(--e-cl-border-50pc);
      border-top-left-radius: var(--e-r-default);
      border-bottom-left-radius: var(--e-r-default);
    }
    input {
      width: 100%;
      box-sizing: border-box;
      padding: 1.6rem;
      outline: none;
      border: none;
      background-color: transparent;
    }
    &.error {
      border: 1px solid var(--e-cl-neg);
    }

    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active {
      border: 1px solid var(--e-cl-pri-50pc);
    }
  }
}
